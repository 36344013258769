import React, { useContext, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { navigate } from "gatsby";
import moment from "moment";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FilledInput from "@material-ui/core/FilledInput";

import {
  Button,
  Box,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Hidden,
  Typography,
  Dialog,
  IconButton,
  AppBar,
  Toolbar,
  FormControl,
  NativeSelect,
  Paper,
  InputBase,
  makeStyles,
  useTheme,
  useMediaQuery,
  withStyles,
} from "@material-ui/core";
import { Link } from "gatsby-material-ui-components";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";

import Layout from "../components/layout";
import Container from "../components/Container";
import RefineList from "../components/RefineList";
import InfiniteScroll from "react-infinite-scroll-component";

import i18nContext from "../i18n-context";

import useLanguage from "../hooks/useLanguage";

const SearchPage = ({ location, pageContext }) => {
  const classes = useStyles();
  const muiTheme = useTheme();
  const language = useLanguage();
  const i18n = useContext(i18nContext)[language];
  const isDesktop = useMediaQuery(muiTheme.breakpoints.up("lg"));
  const isPhone = useMediaQuery(muiTheme.breakpoints.down("xs"));

  const [url, setUrl] = useState(location.search);
  const [sortBy, setSortBy] = useState("new");
  const [parameter, setParameters] = useState({});
  const [searchName, setSearchName] = useState([]);
  const [articles, setArticles] = useState([]);
  const [lists, setLists] = useState([]);
  const [dataLength, setDataLength] = useState(10);

  const [fetching, setFetching] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const pageTranslations = pageContext.translated;
  const options = [
    { value: "new", title: i18n.searchPage.sortList.new },
    { value: "old", title: i18n.searchPage.sortList.old },
    { value: "relevant", title: i18n.searchPage.sortList.relevance },
  ];

  const isTc = language === "tc";
  const isSc = language === "sc";

  if (url !== location.search) {
    setUrl(location.search);
  }

  const fetchDate = async () => {
    setFetching(true);
    setInputValue(location.search);
    let lang = "&lang=";
    lang += location.pathname.includes("zh-hant")
      ? "zh-hant"
      : location.pathname.includes("zh-hans")
      ? "zh-hans"
      : "en";
    let orderBy = `&order_by=${sortBy}`;

    // let fetchingUrl = `http://fbig.smartdrop.com.hk/internal-site/wp-json/wp/v2/all-posts${location.search}${lang}${orderBy}`
    let fetchingUrl = `${process.env.WP_REST_URL}/all-posts${location.search}${lang}${orderBy}`;
    await fetch(fetchingUrl)
      .then((res) => res.json())
      .then((result) => {
        // console.log("res:", result);
        if (result.posts.length === 0) setArticles([]);
        setArticles(result.posts);

        if (result.detail.length > 0) setLists([]);
        setFetching(false);
        setLists(result.detail);
      })
      .catch((error) => {
        console.log(error);
        setArticles([]);
        setLists([]);
        setFetching(false);
      });
  };

  const handleParams = async () => {
    const queryParams = new URLSearchParams(location.search);
    const search = queryParams.get("search");

    if (location && location.search) {
      let queryString = search ? search.replace("?search=", "").split(",") : [];
      let result = null;
      if (
        queryString &&
        queryString !== "" &&
        JSON.stringify(queryString) !== JSON.stringify(searchName)
      ) {
        await setSearchName(queryString);
        result = queryString;
      }
      if (queryString.length === 0) {
        setSearchName([]);
      }
      let tmpObject = {};
      items.map((item) => {
        let tmp = queryParams.get(item.value);
        if (tmp && tmp !== "") {
          tmpObject[item.value] = tmp;
        }
        return tmp;
      });
      setParameters(tmpObject);
      return result;
    }
  };

  useEffect(() => {
    let tmp = handleParams();
    fetchDate(tmp);
  }, [url, sortBy]);

  const selectChange = (select) => {
    // console.log("select:", select)
    setSortBy(select.target.value);
  };

  const handleDelete = (paramToDelete) => () => {
    // console.log("handleDelete")
    let newParamArray = searchName;
    newParamArray.splice(paramToDelete, 1);
    // console.log("newParamArray:", newParamArray)
    setSearchName(newParamArray);
    changeUrl(newParamArray, parameter);
  };

  const deleteAllSearch = () => {
    // console.log("deleteAllSearch")
    setSearchName([]);
    setParameters({});
    changeUrl([], {});
  };

  const handleParamDelete = (key) => {
    // console.log("key:", key)
    let newParamArray = parameter;
    delete newParamArray[key];
    // console.log("newParamArray:", newParamArray)
    setParameters(newParamArray);
    changeUrl(searchName, newParamArray);
  };

  const changeUrl = (search, param) => {
    let paramUrlPart = "";
    Object.entries(param).map((value) => {
      if (value[1]) {
        paramUrlPart += `&${value[0]}=${encodeURIComponent(value[1])}`;
      }
      return paramUrlPart;
    });
    // console.log("paramUrlPart:",paramUrlPart)
    // console.log("searchName:",searchName)
    search = "?search=" + encodeURIComponent(search.join(",")) + paramUrlPart;
    navigate(location.pathname + search);
  };

  const fetchMoreData = () => {
    // console.log("change:", dataLength)
    let remain = articles.length - dataLength;
    let length = remain > 10 ? 10 : remain > 0 ? remain : 0;
    setTimeout(() => {
      setDataLength(dataLength + length);
    }, 1000);
  };

  const handleTextFieldChange = (event) => {
    setInputValue(event.target.value);
  };

  const serachIconButtonClick = () => {
    let searchUrl = "/search?search=" +  encodeURIComponent(inputValue) ;
    searchUrl = isTc
      ? "/zh-hant" + searchUrl
      : isSc
      ? "/zh-hans" + searchUrl
      : searchUrl;
    navigate(searchUrl);
  };

  const triggrtDialog = () => {
    // console.log("openDialog")
    setOpenDialog(!openDialog);
  };

  const closeDialog = () => {
    // console.log("closeDialog")
    setOpenDialog(false);
  };

  const searchNameChipsArea = () => {
    return searchName.map((value, index) => {
      return (
        <Chip
          key={"key-" + index}
          label={`"${value}"`}
          onDelete={handleDelete(index)}
          className={classes.chip}
        />
      );
    });
  };

  const parameterChipsArea = () => {
    return Object.entries(parameter).map((value, index) => {
      if (value[1]) {
        let chip = value[1];
        if (
          value[0] === "status" ||
          value[0] === "publication_date" ||
          value[0] === "content_type"
        ) {
          chip = i18n.searchPage[value[0]][chip];
        }
        return (
          <Chip
            key={"parameter-" + index}
            label={chip}
            onDelete={() => handleParamDelete(value[0])}
            className={classes.chip}
          />
        );
      } else {
        return null;
      }
    });
  };

  const items = [
    { name: i18n.searchPage.status.status, value: "status" },
    {
      name: i18n.searchPage.publication_date.publication,
      value: "publication_date",
    },
    { name: i18n.searchPage.content_type.content_type, value: "content_type" },
    { name: i18n.searchPage.topics, value: "topics" },
    { name: i18n.searchPage.geographies, value: "geographies" },
    { name: i18n.searchPage.research_categories, value: "research_categories" },
    { name: i18n.searchPage.report_series, value: "report_series" },
  ];

  return (
    <Layout pageTranslations={pageTranslations}>
      <Helmet>
      <title>{`${i18n.websiteName} - ${i18n.searchPage.search}`}</title>
      </Helmet>
      <Hidden smDown>
        <div className={`${classes.sansSerif} ${classes.searchTagArea}`}>
          <Container disableGutters={isDesktop}>
            <Grid container alignItems="center">
              <Typography
                variant={isPhone ? "h5" : "h4"}
                className={classes.searchTitle}
              >
                {i18n.searchPage.title}
              </Typography>
              <Box flexGrow={1}>
                {searchNameChipsArea()}
                {parameterChipsArea()}
              </Box>
              <Button className={classes.clearButton} onClick={deleteAllSearch}>
                {i18n.searchPage.clearAll}
              </Button>
            </Grid>
          </Container>
        </div>
      </Hidden>
      {fetching ? (
        <div className={classes.circularProgress}>
          <CircularProgress />
        </div>
      ) : articles.length > 0 ? (
        <Container className={classes.sansSerif}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            aria-label="serach-result-sorting-options-bar"
          >
            <Grid item xs={6}>
              <Typography className={classes.resultText}>
                {articles.length}
                {i18n.searchPage.results}
              </Typography>
            </Grid>

            <Grid
              item
              xs={6}
              container
              justifyContent="flex-end"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <Typography component="div">
                  <strong>{i18n.searchPage.sort}</strong>
                </Typography>
              </Grid>

              <Grid item>
                <FormControl>
                  <StyledSelect
                    value={sortBy}
                    onChange={selectChange}
                    variant="filled"
                    input={<StyledFilledInput />}
                  >
                     {options.map((option, index) => (
                      <MenuItem value={option.value} key={"option-" + index}>
                        {option.title}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Hidden mdUp>
            <div>
              <Typography
                onClick={triggrtDialog}
                variant="button"
                gutterBottom
                className={classes.refineButton}
              >
                {i18n.searchPage.refine}{" "}
                {Object.entries(parameter).length > 0
                  ? `(${Object.entries(parameter).length})`
                  : null}
              </Typography>
              <Typography
                onClick={deleteAllSearch}
                variant="button"
                className={classes.mobileClearButton}
              >
                <CloseIcon />
                {i18n.searchPage.clearAll}
              </Typography>
            </div>

            <Dialog
              className={classes.dialog}
              fullScreen={isPhone}
              fullWidth={true}
              maxWidth="xs"
              open={openDialog}
              onClose={closeDialog}
            >
              <AppBar className={classes.dialogAppBar}>
                <Toolbar>
                  <Typography variant="h6" className={classes.dialogTitle}>
                    {i18n.searchPage.refine}
                  </Typography>
                  <IconButton
                    edge="end"
                    color="inherit"
                    onClick={closeDialog}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                </Toolbar>
              </AppBar>
              <div className={classes.dialogChipArea}>
                {searchNameChipsArea()}
                {parameterChipsArea()}
              </div>
              <RefineList
                lists={lists}
                location={location}
                parameterObject={parameter}
                searchName={searchName}
                dialogValue={openDialog}
                closeDialog={closeDialog}
              />
            </Dialog>
          </Hidden>

          <Hidden smDown>
            <Divider className={classes.divider} />
          </Hidden>

          <Grid container alignItems="flex-start" spacing={2}>
            <Hidden smDown>
              <Grid item className={classes.leftRefineColumn} sm>
                <RefineList
                  lists={lists}
                  location={location}
                  parameterObject={parameter}
                  searchName={searchName}
                  dialogValue={openDialog}
                  closeDialog={closeDialog}
                />
              </Grid>
            </Hidden>

            <Grid item sm>
              <InfiniteScroll
                dataLength={dataLength}
                next={() => fetchMoreData()}
                hasMore={dataLength < articles.length}
                className={classes.infiniteScroll}
                loader={
                  <Typography variant={isPhone ? "h5" : "h4"}>
                    {i18n.loading}
                  </Typography>
                }
                scrollableTarget="scrollableDiv"
              >
                {articles.slice(0, dataLength).map((item, index) => {
                  // console.log(item);
                  let langLink = isTc ? '/zh-hant' : isSc ? '/zh-hans' : ''
                  let link = `${langLink}/${item.type}/${item.slug}`;
                  let target = "_self";
                  if (item.type === "press_release") {
                    if (item.press_type === "2") {
                      link = item.news_link;
                    }
                    if (item.press_type === "1") {
                      link = item.press_release_pdf_files;
                    }
                    target = "_blank";
                  }
                  if (item.type === "speech") {
                    link = item.speech_pdf_files;
                    target = "_blank";
                  }
                  return (
                    <div key={"cotnent" + index}>
                      <Link to={link} target={target} underline="none">
                        {/* <h2 className={classes.itemTitle}>{item.title}</h2> */}
                        <Typography
                          variant={isPhone ? "h5" : "h4"}
                          className={classes.itemTitle}
                        >
                          {item.title}
                        </Typography>
                      </Link>

                      <span className={classes.red}>{item.type ? i18n.searchPage.content_type[item.type] : ""}</span>
                      <span>{item.research_categories ?  " | " + item.research_categories : ""}</span>
                      <span>
                        {" | " + moment(item.publication_date, "YYYYMMDD").format(
                          "YYYY-MM-DD"
                        )}
                      </span>
                      <span>{item.author ?  " | " + item.author : ""}</span>

                      <Typography
                        variant={isPhone ? "body2" : "body1"}
                        className={classes.itemSynopsis}
                      >
                        {item.synopsis}
                      </Typography>
                      <Divider className={classes.listDivider} />
                    </div>
                  );
                })}
              </InfiniteScroll>
            </Grid>
          </Grid>
        </Container>
      ) : (
        <div className={classes.failResult}>
          <Typography variant={isPhone ? "h5" : "h4"}>
            {i18n.searchPage.noMatch}
          </Typography>
          <Typography
            className={classes.searchNameText}
            variant={isPhone ? "h5" : "h4"}
          >
            {searchName.join(",")}
          </Typography>

          <Typography variant={isPhone ? "body2" : "body1"}>
            {i18n.searchPage.haveYouTried}
          </Typography>
          <Typography variant={isPhone ? "body2" : "body1"}>
            {i18n.searchPage.tips1}
          </Typography>
          <Typography variant={isPhone ? "body2" : "body1"}>
            {i18n.searchPage.tips2}
          </Typography>
          <Typography variant={isPhone ? "body2" : "body1"} dangerouslySetInnerHTML={{ __html: i18n.searchPage.tips3 }}>
          </Typography>
          <Paper elevation={0} square className={classes.searchInput}>
            <InputBase
              className={classes.input}
              onChange={handleTextFieldChange}
              placeholder={i18n.searchPage.search}
            />
            <IconButton
              onClick={serachIconButtonClick}
              className={`${classes.iconButton} ${classes.smallIcon}`}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
          </Paper>
        </div>
      )}
    </Layout>
  );
};

const StyledFilledInput = withStyles((theme) => ({
  root: { borderRadius: 2 },
}))((classes) => <FilledInput disableUnderline {...classes} />);

const StyledSelect = withStyles((theme) => ({
  select: {
    padding: 6,
    paddingLeft: 12,
  },
}))((classes) => (
  <Select
    {...classes}
    MenuProps={{
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "left",
      },
      getContentAnchorEl: null,
    }}
  />
));

const useStyles = makeStyles((theme) => ({
  divider: {
    // backgroundColor: theme.palette.secondary.main,
    // // marginTop: theme.spacing(2),
    // marginBottom: theme.spacing(2),

    backgroundColor: "#B3B3B3",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      marginLeft: `-${theme.spacing(1)}px`,
      marginRight: `-${theme.spacing(1)}px`,
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: `-${theme.spacing(4)}px`,
      marginRight: `-${theme.spacing(4)}px`,
    },
  },

  listDivider: {
    backgroundColor: "#B3B3B3",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  resultText: {
    fontFamily: "Open Sans",
    fontSize: "18px",
    padding: "12px 0px",
    [theme.breakpoints.up("md")]: {
      fontSize: "24px",
      padding: "16px 0px",
    },
  },

  leftRefineColumn: {
    width: 264,
    maxWidth: 264,
  },

  searchTagArea: {
    background: "#e3e3e3",
    padding: "25px 0px",
  },

  clearButton: {
    fontWeight: "bold",
    fontSize: "20px",
  },

  mobileClearButton: {
    display: "inline-flex",
    alignItems: "center",
    cursor: "pointer",
    fontSize: "0.75rem",
  },

  refineButton: {
    textAlign: "center",
    padding: "5px",
    border: "#CE202E 2px solid",
    display: "block",
    cursor: "pointer",
  },

  cancelButton: {
    display: "flex",
    justifyContent: "center",
  },

  circularProgress: {
    justifyContent: "center",
    margin: "auto",
  },

  nested: {
    padding: `0 0 0 ${theme.spacing(4)}px`,
    margin: "0",
    textAlign: "start",
  },

  subListItem: {
    textAlign: "start",
  },

  sansSerif: {
    fontFamily: "Open Sans",
  },

  searchTitle: {
    paddingRight: 20,
    fontFamily: "Open Sans",
    display: "inline",
  },

  searchInput: {
    marginTop: "10px",
    display: "flex",
    alignItems: "center",
    border: "grey 3px solid",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },

  failResult: {
    padding: 20,
    margin: "auto",
    justifyContent: "start",
  },

  searchNameText: {
    fontWeight: "bold",
    paddingBottom: theme.spacing(3),
  },

  fontWeightNormal: {
    fontWeight: 400,
  },

  fontHide: {
    display: "none",
  },

  chip: {
    // padding: theme.spacing(1),
    // margin: theme.spacing(0.5),
    fontSize: 18,
    borderRadius: 0,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(1),
    color: theme.palette.secondary.main,
    backgroundColor: "white !important",
  },

  itemTitle: {
    marginTop: 0,
    marginBottom: theme.spacing(2),
    // fontFamily: "'Source Serif Variable'",
  },

  itemSynopsis: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  infiniteScroll: {
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(2),
    },
  },

  nativeSelect: {
    // backgroundColor: theme.palette.background.paper,
  },

  dialogAppBar: {
    position: "relative",
  },
  dialogTitle: {
    flex: 1,
  },
  dialogChipArea: {
    padding: "10px 0px",
  },
  dialog: {
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(6),
    },
  },

  red:{
    color: theme.palette.error.main
  }
}));

export default SearchPage;
