import React, { useContext, useState, useEffect } from "react";
import { navigate } from "gatsby";

import { makeStyles } from "@material-ui/core";
import {
  Button,
  Collapse,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { Link } from "gatsby-material-ui-components";

import i18nContext from "../i18n-context";

const RefineList = ({
  lists,
  location,
  parameterObject,
  searchName,
  dialogValue,
  closeDialog,
}) => {
  const classes = useStyles();
  const i18n = useContext(i18nContext);
  const [open, setOpen] = useState({});
  const [parameter, setParameters] = useState({});
  const [openDialog, setOpenDialog] = useState(false);

  const items = [
    { name: i18n.searchPage.status.status, value: "status" },
    {
      name: i18n.searchPage.publication_date.publication,
      value: "publication_date",
    },
    { name: i18n.searchPage.content_type.content_type, value: "content_type" },
    { name: i18n.searchPage.topics, value: "topics" },
    { name: i18n.searchPage.geographies, value: "geographies" },
    { name: i18n.searchPage.research_categories, value: "research_categories" },
    { name: i18n.searchPage.report_series, value: "report_series" },
  ];
  let count = items.length;

  useEffect(() => {
    if (parameterObject) {
      setParameters(parameterObject);
    }
    if (parameterObject) {
      setOpenDialog(dialogValue);
    }
  });

  const itemClick = (item) => {
    // const name = item;
    setOpen({
      ...open,
      [item]: !open[item],
    });
  };

  const subItemClick = async (item, element) => {
    let object = {
      ...parameter,
      [item]: element,
    };
    setParameters(object);
    changeUrl(searchName, object);
  };

  const changeUrl = (search, param) => {
    let paramUrlPart = "";
    Object.entries(param).map((value) => {
      if (value[1]) {
        paramUrlPart += `&${value[0]}=${encodeURIComponent(value[1])}`;
      }
      return paramUrlPart;
    });
    search = "?search=" + encodeURIComponent(search.join(",")) + paramUrlPart;
    navigate(location.pathname + search);
  };

  return (
    <List dense={true}>
      {items.map((item, index) => {
        if (
          !parameter[item.value] &&
          lists[item.value] &&
          Object.keys(lists[item.value]).length > 0
        ) {
          count += 1;
          return (
            <div key={"list" + index}>
              <ListItem
                button
                key={"list-item-" + index}
                onClick={() => itemClick(item.value)}
              >
                <ListItemText primary={item.name} />
              </ListItem>
              <Collapse in={open[item.value]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {Object.entries(lists[item.value]).map((element) => {
                    let subItemName = element[0];
                    if (
                      item.value === "status" ||
                      item.value === "publication_date" ||
                      item.value === "content_type"
                    ) {
                      subItemName = i18n.searchPage[item.value][subItemName];
                    }
                    if (element[1] > 0 || element[1].count > 0) {
                      return (
                        <ListItem key={element[0]} className={classes.nested}>
                          <Link
                            underline="none"
                            component="button"
                            onClick={() => subItemClick(item.value, element[0])}
                          >
                            <ListItemText
                              className={classes.subListItem}
                              secondary={`${subItemName} (${element[1] > 0 ? element[1] : element[1].count})`}
                            />
                          </Link>
                        </ListItem>
                      );
                    } else if (element[0] == 'group') {
                      return (
                        Object.entries(element[1]).map((childTopic, childIndex) => {
                          if (childTopic[0] !== 'tax_position') {
                            return (
                              <div key={`list-topics-${childTopic}-${childIndex}`}>
                                <ListItem
                                  button
                                  key={"list-topics-subItem-" + childIndex}
                                  className={classes.nested}
                                  onClick={() => itemClick("subTopic-" + childTopic[0])}
                                >
                                  <ListItemText secondary={childTopic[0]} />
                                </ListItem>
                                <Collapse in={open[`subTopic-${childTopic[0]}`]} timeout="auto" unmountOnExit>
                                  <List component="div" disablePadding>
                                    {
                                      Object.entries(childTopic[1]).map((childTopicItem) => {
                                        return (
                                          <ListItem className={classes.subNested} key={childTopicItem[0]}>
                                            <Link
                                              underline="none"
                                              component="button"
                                              onClick={() => subItemClick(item.value, childTopicItem[0])}
                                            >
                                              <ListItemText
                                                className={classes.subListItem}
                                                secondary={`${childTopicItem[0]} (${childTopicItem[1] > 0 ? childTopicItem[1] : childTopicItem[1].count})`}
                                              />
                                            </Link>
                                          </ListItem>
                                        )
                                      })}
                                  </List>
                                </Collapse>
                              </div>
                            )
                          }
                        })
                      )
                    }
                  })}
                </List>
              </Collapse>
            </div>
          );
        } else {
          return null;
        }
      })}
      {count === items.length && openDialog ? (
        <div className={classes.cancelButton}>
          <Button onClick={closeDialog}> {i18n.searchPage.cancel}</Button>
        </div>
      ) : null}
    </List>
  );
};

const useStyles = makeStyles((theme) => ({
  nested: {
    padding: `0 0 0 ${theme.spacing(4)}px`,
    margin: "0",
    textAlign: "start",
  },

  subNested: {
    padding: `0 0 0 ${theme.spacing(8)}px`,
    margin: "0",
    textAlign: "start",
  },

  subListItem: {
    textAlign: "start",
  },

  cancelButton: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default RefineList;
